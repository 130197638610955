import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { MainButton } from "../../styles/buttons"
import { CloseButton } from "../forms/reusables"
import { Flex } from "../reusables"

const CovidContainer = styled(Flex)`
    background: white;
    z-index: 1;
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    position: relative;
`
const Title = styled.h2`
    text-transform: uppercase;
    font-weight: 600;
`
const Copy = styled.p`
    font-size: 16px;
`

const CovidPolicy = ({ showing, noClose, covidData }) => {
    return (
        <CovidContainer direction="column" align="center">
            {!noClose && <CloseButton absolute dark handleClose={showing} />}
            <Title>{covidData.title}</Title>
            <Copy>{covidData.copy}</Copy>
            <Link to="/covid-19-policy">
                <MainButton>{covidData.cta}</MainButton>
            </Link>
        </CovidContainer>
    )
}
export default CovidPolicy
