import React from "react"
import styled from "styled-components"
import { MainButton } from "../../styles/buttons"
import useInView from "react-cool-inview"
import { BOOKING_LINK } from "../../project-constants/constants"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

const Container = styled.div`
    margin-bottom: ${props => (!props.last ? "80px" : "100px")};
    display: grid;
    width: 100%;
    grid-template-columns: 50% 1fr;
    column-gap: 5%;

    margin-top: -200px;
    padding-top: 200px;

    @media (max-width: 1000px) {
        padding: 0 20px;
        margin-top: -200px;
        padding-top: 200px;
    }

    @media (max-width: 768px) {
        padding: 0;
        margin-top: -140px;
        padding-top: 140px;
        grid-template-columns: 100%;
        margin-bottom: ${props => (!props.last ? "80px" : "10px")};
    }
`
const ImageContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    order: ${props => (props.odd ? 1 : 0)};

    .imageThumbnail {
        img {
            margin: 0;
        }
    }

    @media (max-width: 768px) {
        order: 0;
    }
`

const Content = styled.div`
    flex: 1;

    @media (max-width: 768px) {
        padding: 20px;
        text-align: center;
        /* grid-template-columns: 100%; */
    }
`
const Title = styled.h2`
    text-transform: uppercase;
    font-size: 50px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        font-size: 35px;
    }
`
const Description = styled.p`
    font-size: 18px;
    line-height: 1.4;

    @media (max-width: 768px) {
        font-size: 16px;
        width: 100%;
        max-width: 50ch;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    @media (max-width: 550px) {
        text-align: left;
    }
`
const Features = styled.div`
    margin-bottom: 40px;
`
const Heading = styled.h5`
    font-size: 18px;

    @media (max-width: 768px) {
        font-size: 16px;
        width: 100%;
    }
`
const FeatureList = styled.ul`
    margin: 0;
    list-style: none;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    max-height: 390px;
    flex-basis: 50%;
    width: 100%;

    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 50% 50%;
        font-size: 16px;
        list-style: circle;
        width: 100%;
        text-align: left;
        padding: 10px;
    }

    @media (max-width: 1000px) {
        max-height: none;
    }
`
const FeatureItem = styled.li`
    font-size: 18px;
    flex-basis: 50%;
    /* flex: 1; */
    padding-right: 15px;
    margin-bottom: 8px;
    max-width: 32ch;
`
const A = styled.a`
    text-decoration: none;
    color: inherit;
`

const RoomItem = ({ item, odd, setCurrentSection, last }) => {
    const { ref } = useInView({
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        threshold: 0.5,
        onEnter: () => {
            setCurrentSection(`#${item.id}`)
        },
    })

    const roomImages = !item.gallery
        ? []
        : item.gallery.map(el => ({
              original: el.sourceUrl,
              thumbnail: el.sourceUrl,
          }))

    return (
        <Container
            last={last}
            id={item.id}
            ref={ref}
            justify="space-between"
            direction={odd ? "row-reverse" : "row"}
        >
            <ImageContainer odd={odd}>
                <ImageGallery
                    items={roomImages}
                    originalClass="imageThumbnail"
                    showThumbnails={false}
                    showBullets={true}
                    showPlayButton={false}
                    showNav={false}
                />
            </ImageContainer>
            <Content>
                <Title>{item.title}</Title>
                <Description>{item.description}</Description>
                <Features>
                    <Heading>Room Features:</Heading>
                    <FeatureList>
                        {item.roomFeatures.map((feature, index) => (
                            <FeatureItem key={index}>
                                {feature.label}
                            </FeatureItem>
                        ))}
                    </FeatureList>
                </Features>

                <A target="_blank" rel="no referrer" href={BOOKING_LINK}>
                    <MainButton>Book your room</MainButton>
                </A>
            </Content>
        </Container>
    )
}

export default RoomItem
