import React from "react"
import styled from "styled-components"
import { MaxWidthContainer } from "../layout"
import RoomItem from "./room-item"

const Container = styled(MaxWidthContainer)``

const RoomList = ({ setCurrentSection, rooms }) => {
    return (
        <Container>
            {rooms.map((room, index) => (
                <RoomItem
                    key={index}
                    id={`${room.title[0]}-${index}`}
                    item={{ ...room, id: `${room.title[0]}-${index}` }}
                    odd={index % 2 === 1}
                    setCurrentSection={setCurrentSection}
                    last={index === roomData.length - 1}
                />
            ))}
        </Container>
    )
}

export default RoomList

export const roomData = [
    {
        id: "standard",
        title: "Standard Rooms",
        description:
            "Unwind in one of the standard rooms located in our main building, nestled amid 18 acres of beautifully landscaped gardens. Each room has its own private balcony, which offers scenic views of the Caribbean Sea, the pool, our grounds, or golf course.",
        features: [
            "Two double beds/one king bed",
            "Private balcony/patio",
            "Sea, pool or garden view",
            "Deluxe toiletries and bathrobes",
            "Multi-voltage shaver socket",
            "Shower over bath",
            "Air conditioning",
            "In-room safe",
            "Hairdryer",
            "Cable TV",
            "Desk",
            "Wi-Fi",
        ],
    },
    {
        id: "superior",
        title: "Superior Rooms",
        description:
            "A step up. Our newly renovated Superior Rooms feature all of the comforts of our Standard Rooms, but with upgraded quality fixtures and fittings.",
        features: [
            "Modern upgrades",
            "Two double beds/one King bed",
            "Desk",
            "Sea, pool or garden views",
            "Air conditioning",
            "Private balcony/patio",
            "In-room safe",
            "Deluxe toiletries and bathrobes",
            "Hairdryer",
        ],
    },
    {
        id: "suites",
        title: "Suites",
        description:
            "Need more space? Stay in one of our suites*, each with a sitting area equipped with fully upholstered sofas and chairs, and rooms that open onto private balconies with scenic views. One-bedroom suites have one King-sized bed, while our two-bedroom suites have one King-sized bed and two Double beds.",
        features: [
            "Sea, pool, or garden views",
            "Desk",
            "Air conditioning",
            "Private balcony/patio",
            "In-room safe",
            "Deluxe toiletries and bathrobes",
            "Hairdryer",
            "Multi-voltage shaver socket",
            "Shower over bath",
            "Cable TV",
            "Iron and Ironing board",
            "Tea and coffee station",
            "Mini fridge",
            "USB charging ports",
            "Wi-Fi",
        ],
    },
    {
        id: "cottages",
        title: "The Garden Cottages",
        description:
            "A little isolation can be a good thing. Stay in one of our spacious Garden Cottages and you’ll see what we mean. Scattered throughout our property, these private cottages come with a choice of open or enclosed patio, and are set on well-manicured, expansive lawns perfect for private entertaining or sunbathing with spectacular views of the Caribbean Sea, our large pool or our tropical gardens as living backdrops.",
        features: [
            "Modern upgrades",
            "Two double beds/one King bed",
            "Desk",
            "Sea, pool or garden views",
            "Air conditioning",
            "Private balcony/patio",
            "In-room safe",
            "Deluxe toiletries and bathrobes",
            "Hairdryer",
            "Magnifying mirror",
            "Multi-voltage shaver socket",
            "Shower over bath",
            "Cable TV",
            "Iron and Ironing board",
            "Tea and coffee station",
            "Mini fridge",
            "USB charging ports",
            "Wi-Fi",
        ],
    },
]
