import React, { useState } from "react"
import CovidPolicy from "../components/home/covid-policy"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import { Flex } from "../components/reusables"
import RoomList from "../components/rooms/room-list"
import SEO from "../components/seo"
import Subnavigation from "../components/subnavigation"
import TitleBlock from "../components/title-block"
import useInView from "react-cool-inview"
import { graphql } from "gatsby"
import FooterGallery from "../components/footer-gallery"

const RoomsPage = ({ data }) => {
    const roomData = data.roomsData.room
    // const covidData = data.wpPost.covid_banner
    const { footerGallery } = data.roomsData?.room

    const [currentSection, setCurrentSection] = useState("#standard")

    const { ref } = useInView({
        // Shrink the root margin, so the animation will be triggered once the target reach a fixed amount of visible
        threshold: 0.5,
        onEnter: () => {
            console.log("Entered")
            // setCurrentSection(`#stayingsafe`)
        },
    })

    const menu = roomData.rooms.map((room, index) => ({
        title: room.title,
        anchor: `#${room.title[0]}-${index}`,
    }))

    return (
        <Layout>
            <SEO title="Rooms" />
            <PageHeader title="Rooms" />
            <Subnavigation currentSection={currentSection} menu={menu} />
            <TitleBlock title={roomData.title} copy={roomData.subtitle} />
            <RoomList
                rooms={roomData.rooms}
                setCurrentSection={setCurrentSection}
            />
            {/* <Flex
                ref={ref}
                id="stayingsafe"
                justify="center"
                align="center"
                style={{ width: "100%" }}
            >
                <CovidPolicy covidData={covidData} noClose />
            </Flex> */}
            {footerGallery && footerGallery.length > 0 && (
                <FooterGallery gallery={footerGallery} />
            )}
        </Layout>
    )
}

export default RoomsPage

export const RoomsQuery = graphql`
    query roomsQuery {
        roomsData: wpPage(title: { eq: "Rooms" }) {
            room {
                footerGallery {
                    sourceUrl
                }
                rooms {
                    description
                    gallery {
                        sourceUrl
                    }
                    roomFeatures {
                        label
                    }
                    title
                }
                subtitle
                title
            }
        }
    }
`

// const menu = [
//     {
//         title: "Standard",
//         anchor: "#standard",
//     },
//     {
//         title: "Superior",
//         anchor: "#superior",
//     },
//     {
//         title: "Suites",
//         anchor: "#suites",
//     },
//     {
//         title: "The Garden Cottages",
//         anchor: "#cottages",
//     },
//     {
//         title: "Staying Safe",
//         anchor: "#stayingsafe",
//     },
// ]
